import { useEffect } from "react";
import { SOCKET_METHOD_INVOKES } from "../../../shared/constants";

import setHubConnectionLogger from "@livecasino/core/helpers/setHubConnectionLogger";

function useEventsSharableFunctionality({
    reducerEventTypes,
    gameName,
    hubConnection,
    connectionStatus,
    areListenersSetRef,
    dispatch,
    onCloseModal,
    onReceiveInfoMessage,
    gameCustomListeners = []
}) {
    useEffect(() => {
        if (areListenersSetRef.current || !hubConnection || !connectionStatus.isConnected) {
            return;
        }

        setHubConnectionLogger(hubConnection, `PitBoss - ${gameName} (Tab:Events)`);

        if (gameCustomListeners.length > 0) {
            for (const { type, listenerType } of gameCustomListeners) {
                hubConnection.on(listenerType, data => {
                    dispatch({
                        type,
                        payload: data
                    });
                });
            }
        }

        hubConnection.on(SOCKET_METHOD_INVOKES.INITIAL_STATE, data => {
            dispatch({
                type: reducerEventTypes.INITIAL_STATE,
                payload: data
            });
        });

        hubConnection.on(SOCKET_METHOD_INVOKES.GAME_SOUND_CHANGED, data => {
            dispatch({
                type: reducerEventTypes.GAME_SOUND_CHANGED,
                payload: data
            });
        });

        hubConnection.on(SOCKET_METHOD_INVOKES.REQUEST_INITIAL_ACTIONS_STATE, () => {
            hubConnection.invoke(SOCKET_METHOD_INVOKES.GET_INITIAL_STATE);
        });

        hubConnection.on(SOCKET_METHOD_INVOKES.TABLE_STATUS_CHANGED, data => {
            dispatch({
                type: reducerEventTypes.TABLE_STATUS_CHANGED,
                payload: data
            });
        });

        hubConnection.on(SOCKET_METHOD_INVOKES.ACTION_STATE_CHANGED, data => {
            dispatch({
                type: reducerEventTypes.ACTION_STATE_CHANGED,
                payload: data
            });
        });

        hubConnection.on(SOCKET_METHOD_INVOKES.EVENTS_CHANGED, data => {
            dispatch({
                type: reducerEventTypes.CHANGE_EVENTS,
                payload: data
            });
        });

        hubConnection.on(SOCKET_METHOD_INVOKES.INFO_MESSAGE, data => {
            const { message } = data;
            if (typeof onReceiveInfoMessage === "function") {
                onReceiveInfoMessage(message);
            }
        });

        hubConnection.on(SOCKET_METHOD_INVOKES.DEALER_LOGGED_IN, isDealerLoggedIn => {
            dispatch({
                type: reducerEventTypes.SET_DEALER_LOGGED_IN,
                payload: isDealerLoggedIn
            });
            if (isDealerLoggedIn && typeof onCloseModal === "function") {
                onCloseModal();
            }
        });

        areListenersSetRef.current = true;

        hubConnection.invoke(SOCKET_METHOD_INVOKES.GET_INITIAL_STATE);
    }, [
        areListenersSetRef,
        connectionStatus.isConnected,
        gameCustomListeners,
        dispatch,
        gameName,
        hubConnection,
        onCloseModal,
        onReceiveInfoMessage,
        reducerEventTypes
    ]);
}

export default useEventsSharableFunctionality;
