import React, { useState, useCallback, useMemo } from "react";
import Game from "../Game/Game";
import classNames from "classnames";
import usePitBossData from "hooks/usePitBossData";
import ErrorContent from "@livecasino/core/v2/Backoffice_DMS_Pitboss/components/ErrorContent/ErrorContent";
import Modal from "@livecasino/core/v2/components/Modal";
import getErrorObjectByType from "@livecasino/core/helpers/getErrorObjectByType";
import { ERROR_TRANSLATIONS, GAME_KINDS, CLASSIC_BJ_AVAILABLE_SEATS } from "shared/constants";
import StreamSwitchRadioButtons from "../StreamSwitchRadioButtons";
import { STREAM_SOURCES } from "../../shared/constants";
import ChatTab from "../NavigationTabs/shared/ChatTab";

import styles from "./styles.module.scss";

const PitBossLobby = ({ getAsyncTokens, getAsyncToken, tableIds, requestInstance }) => {
    const [selectedStreamSource, setSelectedStreamSource] = useState(STREAM_SOURCES.IMAGES);

    const { games, loading, error } = usePitBossData({ getAsyncToken, tableIds });
    const errorObject = useMemo(() => {
        if (!error) {
            return null;
        }
        return getErrorObjectByType({ error, translation: ERROR_TRANSLATIONS });
    }, [error]);

    const [zoomedVideoId, setZoomedVideoId] = useState(null);

    const handleVideoZoom = useCallback(tableId => {
        setZoomedVideoId(prevTableId => (prevTableId === tableId ? null : tableId));
    }, []);

    let zoomedGame = useMemo(() => {
        if (!zoomedVideoId) {
            return null;
        }
        return games.find(game => game.tableId === zoomedVideoId);
    }, [games, zoomedVideoId]);

    const videoContentClasses = classNames(styles.VideoContentWrapper, {
        [styles.GridWrapperInFullScreen]: Boolean(zoomedGame)
    });

    if (loading && !error) {
        return <div className="PopUpContainer">Loading...</div>;
    }

    const lobbyContainerClasses = classNames(styles.VideoContent, {
        "blur-background": error
    });
    const zoomedGameAvailableSeats = zoomedGame?.gameKind === GAME_KINDS.BLACKJACK ? CLASSIC_BJ_AVAILABLE_SEATS : null;
    return (
        <>
            <div className={lobbyContainerClasses}>
                <div className={styles.Header}>
                    <StreamSwitchRadioButtons
                        selectedSource={selectedStreamSource}
                        onsSourceChange={setSelectedStreamSource}
                    />
                </div>
                <div className={styles.VideoContainer}>
                    {zoomedGame && (
                        <div className={styles.VideoIsZoomInClass} key={zoomedGame.tableId}>
                            <div className={styles.VideoWrapper}>
                                <Game
                                    roundId={zoomedGame.roundId}
                                    onVideoZoom={handleVideoZoom}
                                    isVideoZoomed
                                    dealerNickname={zoomedGame.dealerNickname}
                                    availableSeats={zoomedGameAvailableSeats}
                                    players={zoomedGame.players}
                                    betters={zoomedGame.betters}
                                    viewers={zoomedGame.viewers}
                                    tableName={zoomedGame.tableName}
                                    gameName={zoomedGame.gameName}
                                    flagVersion={zoomedGame.flagVersion}
                                    languageCode={zoomedGame.languageCode}
                                    totalAmount={zoomedGame.totalBetAmount}
                                    currencyCode={zoomedGame.currencyCode}
                                    tableId={zoomedGame.tableId}
                                    htmlId="zoomed_video_id"
                                    getAsyncTokens={getAsyncTokens}
                                    streamData={zoomedGame.streamData}
                                    gameKind={zoomedGame.gameKind}
                                    gameType={zoomedGame.gameType}
                                    enabledVideoStreamOrImage={selectedStreamSource}
                                    isGameMuted={zoomedGame.isGameMuted}
                                />
                            </div>
                            <div className={styles.ChatWrapper}>
                                <ChatTab
                                    tableId={zoomedGame.tableId}
                                    getAsyncToken={getAsyncToken}
                                    requestInstance={requestInstance}
                                />
                            </div>
                        </div>
                    )}
                    <div className={videoContentClasses}>
                        {games?.map(game => {
                            let selected = game.tableId === zoomedVideoId;
                            const availableSeats =
                                game.gameKind === GAME_KINDS.BLACKJACK ? CLASSIC_BJ_AVAILABLE_SEATS : null;
                            const areAllSeatsTaken =
                                game.gameKind === GAME_KINDS.BLACKJACK && availableSeats === game.players;
                            return (
                                <div className={styles.GameWrapper} key={game.tableId}>
                                    <Game
                                        tableId={game.tableId}
                                        roundId={game.roundId}
                                        dealerNickname={game.dealerNickname}
                                        availableSeats={availableSeats}
                                        players={game.players}
                                        betters={game.betters}
                                        viewers={game.viewers}
                                        tableName={game.tableName}
                                        gameName={game.gameName}
                                        actions={game.actions}
                                        onVideoZoom={handleVideoZoom}
                                        selected={selected}
                                        isVideoZoomed={false}
                                        flagVersion={game.flagVersion}
                                        languageCode={game.languageCode}
                                        htmlId={String(game.tableId)}
                                        totalAmount={game.totalBetAmount}
                                        currencyCode={game.currencyCode}
                                        isPaused={game.gameStatus.isPaused}
                                        isActive={game.gameStatus.isActive}
                                        timer={game.gameStatus.timer}
                                        getAsyncTokens={getAsyncTokens}
                                        streamData={game.streamData}
                                        gameKind={game.gameKind}
                                        gameType={game.gameType}
                                        enabledVideoStreamOrImage={selectedStreamSource}
                                        isGameMuted={game.isGameMuted}
                                        areAllSeatsTaken={areAllSeatsTaken}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <Modal open={Boolean(error)} backdropFilterValue="4px" backdropBackgroundColor="transparent">
                <div className={styles.ErrorContentWrapper}>
                    <ErrorContent title={errorObject?.title} texts={errorObject?.text} />
                </div>
            </Modal>
        </>
    );
};

// PitBossLobby.propTypes = {
//     getAsyncToken: PropTypes.func.isRequired,
//     getAsyncTokens: PropTypes.func.isRequired,
//     tableIds: PropTypes.array.isRequired
// };

export default PitBossLobby;
